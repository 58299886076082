.main .email{
    display: flex;
    justify-content: center;
    width: 30%;
    margin: 10px auto;
}

.contact .header{
    position: fixed;
    top: 0;
    left: 0;
    border: none;
    background-color: transparent;
}

.contact .header .page-link{
    color: #23272a;
    font-weight: 500;
}

.c-scrolled .header{
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    transition: all 0.3s ease;
}

.contact-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh);
    width: 100%;
}

.contact-body .cb-clm-left{
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #4b6cb7, #182848);
}

.contact-body .cb-clm-right{
    align-items: center;
    padding: 70px 30px;
}

.contact-body .cb-clm-right .cb-clmr-inner{
    width: 560px;
}

.contact-body .cb-clm-right .cb-clmr-inner .header-h3{
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 30px;
}

.contact-body .cb-clm-right .cb-clmr-inner p{
    font-size: clamp(18px,1.5vw,17px);
    line-height: 180%;
}

.contact-body .cb-clm-right .cb-clmr-social-media-div{
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
}

.contact-body .cb-clm-right .cb-clmr-social-media-div a:hover{
    cursor: pointer;
}

.contact-body .cb-clm-right .msg-sent-success{
    width: 90%;
    margin-left: 5%;
}

.contact-body .cb-clm-right .msg-sent-success p{
    font-size: 16px;
    text-align: center;
}

.contact-body .cb-clm-right .msg-sent-success .sm{
    margin-top: 10px;
}

.contact-body .cb-clm-right .msg-sent-success .sm a{
    color: rgb(63, 163, 240);
}

.cu-msg-container{
    margin-top: 10%;
    padding: 5% 8%;
    background-color: rgb(255, 255, 255);
    border: 1px solid #eff3f4;
    border-radius: 12px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.cu-msg-container h3{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
}

.cu-msg-container .cu-msg-name-email-div{
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cu-msg-container .error .helper-txt,
.cu-msg-container .error-textarea .helper-txt{
    position: absolute;
    display: flex;
    color: #e70000;
    float: left;
    font-size: 12px;
    letter-spacing: 1px;
    z-index: 1;
}

.cu-msg-container .error input,
.cu-msg-container .error-textarea textarea{
    border: 1px solid #e70000;
}

.cu-msg-container .error-textarea .helper-txt{
    top: 64px
}

.cu-msg-container .error .helper-txt{
    top: 40px
}

.user-input{
    position: relative;
    margin-bottom: 25px;
}

.user-input input,
.user-input textarea{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    width: 100%;
    border: 1px solid #e9ebf0;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 20px 8px 40px;
    color: #292d34;
    transition: all ease-in-out 0.15s;
}

.user-input textarea{
    resize: none;
}

.user-input input:focus,
.user-input textarea:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.user-input textarea::placeholder{
    color: #b9bec7;
}

.user-input .name-icon,
.user-input .email-icon,
.user-input .message-icon{
    position: absolute;
    left: 11px;
    font-size: 18px;
    font-weight: 600;
    color: #b9bec7;
}

.user-input .name-icon{
    top: 8px;
}

.user-input .email-icon,
.user-input .message-icon{
    top: 9px;
}

.cu-submit-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 20px;
    height: 34px;
    width: 180px;
    font-weight: 700;
    letter-spacing: 0.045em;
    color: #fff;
    background-color: rgb(63, 163, 240);
    transition: all 0.3s ease;
}

.cu-btns-div{
    margin-top: 15px;
}

.cu-btns-div .cub-header{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.cu-btns-div .cub-header .cubdh-line{
    position: absolute;
    top: 11px;
    left: 15%;
    height: 2px;
    width: 70%;
    background-color: #f7f7f7;
    z-index: 0;
}

.cu-btns-div .cub-header p{
    width: auto;
    margin: 0;
    padding: 0 8px;
    color: rgba(0,0,0,.66) !important;
    background-color: #fff;
    font-size: 13px !important;
    z-index: 10;
}

.cu-btns-div .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media (max-width: 1049px){
    .contact-body .cb-clm-right .cb-clmr-inner {
        width: 100%;
    }

    .cu-msg-container .cu-msg-name-email-div{
        flex-direction: column;
    }

    .user-input{
        width: 100%;
    }
}

@media (max-width: 1049px){
    .contact-body{
        grid-template-rows: auto auto;
        grid-template-columns: none;
    }

    .contact .header .page-link{
        color: #fff;
    }

    .contact .footer{
        position: inherit;
        background-color: inherit;
    }

    .c-scrolled .header .hdr-explore{
        color: #000;
    }

    .contact-body .cb-clm-left{
        height: 400px;
    }

}

@media (max-height: 799px) and (min-width: 849px) {
    .contact .footer {
        display: none;
    }
}

@media only screen and (min-device-width: 849px) {
    .contact-body .header{
        display: none;
    }
}

@media only screen and (max-device-width: 600px) {
    .contact .header .page-link{
        background: transparent;
    }

    .contact-body .cb-clm-right{
        padding: 40px 3% 60px 3%;
    }

    .cu-msg-container{
        margin-top: 60px;
    }

    .contact-body .cb-clm-right .msg-sent-success{
        width: 100%;
        margin: 0;
    }
}