@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,900&display=swap');

body {
  margin: 0;
  background-color: #f7f7f7 !important;
  font-family: 'Lato', sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  scroll-behavior: smooth;
  user-select: none;
  letter-spacing: 0.03em;
  height: 100vh; /* Ensure fixed height */
  overflow-y: scroll; /* Always show vertical scrollbar */
  overscroll-behavior-y: none; /* Disable pull-to-refresh behavior */
}

a{
  text-decoration: none !important;
}

.background-image{
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover;
}

button:focus {
  outline: none;
}

.none{
  display: none;
}

.user-input{
  position: relative;
  margin-bottom: 25px;
}

.user-input input{
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  width: 100%;
  border: 1px solid #e9ebf0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 8px 20px 8px 40px;
  color: #292d34;
  transition: all ease-in-out 0.15s;
}

.user-input label{
  font-weight: 600;
  font-size: 11px;
  line-height: 11px;
  display: block;
  color: #292d34;
  padding: 0 0 8px 2px;
}

.user-input input:focus{
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25);
}

.user-input input::placeholder{
  color: #b9bec7;
  font-size: 16px;
}

.user-input .lni{
  position: absolute;
  top: 29px;
  left: 11px;
  font-size: 18px;
  font-weight: 600;
  color: #b9bec7;
}

.user-input.error .helper-txt{
  position: absolute;
  display: flex;
  top: 60px;
  color: #e70000;
  float: left;
  font-size: 12px;
  z-index: 1;
}

.user-input.error input{
  border-color: #e70000;
}

.display-flex{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}