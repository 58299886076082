.privacy{
    width: 100%;
}

.privacy .header{
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
}

.privacy-sec{
    width: 900px;
    min-height: calc(100vh - 200px);
    padding-top: 70px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    overflow-y: hidden;
    scroll-behavior: smooth;
}

.privacy-sec .ps-header{
    font-size: 23px;
    letter-spacing: 0.025em;
    margin-top: 20px;
    margin-bottom: 10px;
}

.privacy-sec .ps-paragraph{
    width: 100%;
    margin: 0 0 1em 0;
    color: rgba(0,0,0,.56);
    letter-spacing: 0.025em;
    font-size: 15px;
}

.privacy-sec ul{
    list-style-type: circle;
    color: rgba(0,0,0,.56);
    letter-spacing: 0.025em;
}

.privacy-sec ul li{
    font-size: 15px;
    margin: 2px 0;
}

.privacy .header .page-link{
    color: rgb(49, 49, 49);
    font-weight: 500;
}

.privacy .header .page-link:hover{
    background: rgba(215, 215, 215, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
}

@media (max-width: 1000px){
    .privacy-sec{
        width: 90%;
    }
}

@media only screen and (max-device-width: 600px) {
    .privacy .header .page-link{
        background: transparent;
    }
}