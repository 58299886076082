.banner-frame{
    width: 100%;
    position: relative;
    bottom: 50px;
}

.banner-frame-intro{
    margin-bottom: 90px;
}

.banner-frame .background{
    position: absolute; 
    top: 0px; 
    left: 0px; 
    width: 100%; 
    height: calc(50% + 50px); 
    background-color: #000;
    z-index: 0
}

.banner-container{
    position: relative;
    box-sizing: border-box;
    width: 1120px;
    padding: 3%;
    margin: 0 auto;
    z-index: 10;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(239, 243, 244);
    border-radius: 12px;
    transition: all 0.3s ease-in-out;
}

.bc-header{
    margin-bottom: 40px;
}

.bc-header h3{
    letter-spacing: 0.025em; 
    margin-block: 12px;
    font-size: 40px;
}

.bc-header-h3-799{
    font-size: 40px;
}

.bc-header p{
    text-align: left; 
    font-size: 18px; 
    color: #595959;
}

.bc-body{
    display: flex; 
    flex-direction: row;
}

.bc-body-txt{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.bcbt-hdr{
    color: #23272a;
    letter-spacing: 0.025em; 
    font-size: clamp(30px, 4vw, 44px);
    line-height: 140%;
    font-weight: 600;
}

.bcbt-body{
    width: 100%;
    margin-top: 10px;
    font-size: clamp(18px,2vw,20px);
    color: rgba(0,0,0,.66);
    line-height: 1.625em;
    letter-spacing: 0.025em;
}

.banner-img{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.id-poster{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 1550px){
    .banner-container{
        width: 1100px;
    }
}

@media (max-width: 1100px){
    .banner-container{
        width: 96%;
    }
}

@media (max-width: 949px){
    .bc-header-h3-wide-screen{
        font-size: 33px;
    }
}

@media (max-width: 849px){
    .travel-passport-container .banner-container{
        width: 100%;
        padding: 4%;
    }

    .weekly-newsletter-div .bc-body-txt{
        padding: 0;
    }
}

@media (max-width: 799px){
    .bc-body{
        flex-direction: column;
    }

    .bc-body-txt{
        text-align: center;
    }

    .banner-container{
        padding: 6% 4% 7% 4%;
    }

    .banner-img{
        width: 100%;
    }

    .bc-body-txt{
        width: 100%;
        padding: 0 10%;
    }

    .bc-header-h3-wide-screen{
        font-size: 35px;
    }
    
}

@media only screen and (max-device-width: 600px) {
    .bc-header h3{
        font-size: 30px;
        line-height: 38px;
    }

    .bc-body-txt{
        padding: 0;
    }

    .bcbt-hdr{
        width: 100%;
        margin: 0;
        text-align: center;
        font-size: clamp(20px,6.5vw,40px);
        line-height: 145%;
    }

    .banner-container{
        padding: 6% 4% 8% 4%;
    }

    .banner-frame{
        bottom: 70px;
    }

    .bc-header{
        margin-bottom: 20px;
    }

    .bcbt-body{
        text-align: left;
    }

    .banner-frame-intro{
        margin-bottom: 60px;
    }

    .banner-frame-intro .banner-container{
        box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
    }
}

