.explore-profiles-div{
    width: 1120px;
    margin: 0 auto 140px auto;
}

.explore-profiles-div .ep-header{
    display: flex;
    flex-direction: row;
    width: max-content;
    margin: 0 0 60px 0;
    text-align: left;
    font-size: 36px;
    color: #23272a;
}

.explore-profiles-div .ep-header:hover{
    cursor: pointer;
}

.explore-profiles-div .ep-header div{
    height: 100%;
    font-size: 15px;
    margin-left: 8px;
    padding: 0px 7px 1px 7px;
    border-radius: 7px;
    background-color: #f0f2f5;
    color: #000;
    border: 2px solid #f0f2f5;
}

.travel-passport-modal .ct-close-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
}

.ep-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    transition: all 0.25s ease-in-out;
}

.ep-image{
    width: 100px;
    height: 100px;
    border-radius: 100%;
    background-color: #fbfbfb;
    overflow: hidden;
}

.ep-image img{
    object-fit: cover;
    outline: 1px solid rgba(0, 0, 0, .1);
    outline-offset: -1px;
    border-radius: 100%;
}

.ep-more-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95px;
    box-sizing: content-box;
    margin-top: 10px;
    margin-left: auto;
    color: rgb(63, 163, 240);
    padding: 6px 10px;

    position: relative;
    overflow: hidden;
}

.ep-profile-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2%;
    width: calc((100% - 4% * 3)/3);
    margin: 2%;
    border-radius: 12px;
    background-color: #fff;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.ep-profile-container:hover{
    cursor: pointer;
}

.ep-profile-container .img-container{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    overflow: hidden;
}

.ep-profile-container .img-container .mantine-Skeleton-root{
    width: 100px;
    height: 100px;
}

.ep-profile-container .img-container img{
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.epc-name{
    margin: 10px 0 0px 0;
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.03em;
}

.epc-type{
    font-size: 14px;
    letter-spacing: 0.03em;
    color: #737373;
    text-align: center;
}

.cp-action-btns-div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.ep-web-verified{
    position: absolute;
    right: -2px;
    bottom: -2px;
    height: 31px;
    width: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 100px;
}

.ep-web-verified:hover{
    cursor: pointer;
}

.ep-action-btn{
    width: 100%;
    height: 33px;
    font-size: 17px;
    margin-top: 15px;
    letter-spacing: 0.025em;
    color: #fff;
    transition: all 0.25s ease-in-out;
    font-weight: 600;
}

.ep-view-prof{
    background-color: rgb(63, 163, 240);
    color: #fff;
    transition: all 0.25s ease-in-out;
    border-radius: 0 0 12px 12px;
}

.ep-view-prof:hover{
    background-color: #e7f5ff;
    color: rgb(63, 163, 240);
}

.ep-request-itin{
    background-color: #d93471;
}

.ep-request-itin{
    border-right: 1px solid #fff;
}

.ep-request-itin:hover{
    background-color: #fab8d0;
    color: #d93471;
}

.ep-action-btn:hover{
    cursor: pointer;
}

.ep-stats-div{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
}

.ep-stats-div .stat{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 12px 0;
}

.ep-stats-div .stat .value{
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.03em;
}

.ep-stats-div .stat .label{
    font-size: 10px;
    letter-spacing: .025em;
}

.ep-stats-div .ep-sep-line{
    height: 60%;
    width: 1px;
    background-color: #dddddd;
    margin: 0px 10px;
}

.epc-img-div{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 15px;
    width: 100%;
    padding: 0 10px;
}

.epc-place-img{
    width: calc(100% / 3 - 4px);
    aspect-ratio: 1 / 1;
    margin: 2px;
}

.img-container:hover,
.epc-place-img:hover{
    cursor: pointer;
}

.web-exploremore-btn{
    display: flex;
    margin: 40px auto 0 auto;
    background-color: rgb(63, 163, 240);
    color: #fff;
    font-weight: 600;
    padding: 3px 22px;
    border-radius: 4px;
}

.exp-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.epc-img-div-2{
    overflow: hidden;
    position: relative;
    width: 95px;
    height: 95px;
    margin: 2px;
    aspect-ratio: 1/1;
    overflow: hidden;
}

.epc-img-div-2:hover{
    cursor: pointer;
}

.epc-img-div-2 .mantine-Skeleton-root{
    width: 95px;
    height: 95px;
}

.epc-img-div-2 img{
    width: 94px;
    height: 94px;
    object-fit: cover;
}

.stats-container{
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-top: 10px;
}

.stats-container:hover{
    cursor: pointer;
}

.stats-container .stat{
    flex-direction: column;
    align-items: center;
}

.stats-container .stat .value{
    font-size: 24px;
    font-weight: 700;
    letter-spacing: .03em;
}

.stats-container .stat .label{
    margin: 0;
    font-size: 12px;
    letter-spacing: .02em;
    color: #737373;
}

@media (max-width: 1550px){
    .explore-profiles-div{
        width: 1100px;
    }
}

@media (max-width: 1155px){
    .explore-profiles-div{
        width: 100%;
        padding: 0 2%;
        box-sizing: border-box;
    }

    .ep-profile-container{
        width: calc((100% - 2% * 4)/4);
        margin: 1%;
    }

    .ep-profile-container{
        width: calc((100% - 4% * 3)/3);
        margin: 2%;
    }
}

@media only screen and (min-device-width: 850px) {
    .mob-exploremore-btn{
        display: none;
    }
}

@media (max-width: 849px){
    .explore-profiles-div{
        margin-bottom: 100px;
    }

    .ep-profile-container{
        width: auto;
        margin: 2% 1% 2% 2%;
    }

    .explore-profiles-div .ep-header{
        padding: 0 2%;
    }

    .explore-profiles-div{
        padding: 0;
    }

    .ep-more-btn{
        display: none;
    }

    .ep-list{
        flex-wrap: nowrap;
        grid-gap: 0px;
        padding: 0;
        overflow-y: scroll;
    
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
    .ep-list::-webkit-scrollbar{
        display: none;
    }

    .epc-img-div-2,
    .epc-img-div-2,
    .epc-img-div-2 .mantine-Skeleton-root,
    .epc-img-div-2 img{
        width: 105px;
        height: 105px;
    }

    .web-exploremore-btn{
        display: none !important;
    }

    .mob-exploremore-btn{
        height: 60px;
        min-width: 200px;
        margin: auto 2%;
        align-items: center;
        justify-content: center;
        background-color: rgb(232, 241, 248);
        color: rgb(63, 163, 240);
        font-weight: 600;
        border-radius: 4px;
    }
}

@media only screen and (max-device-width: 600px) {
    .explore-profiles-div{
        padding: 0;
    }

    .explore-profiles-container h2{
        padding-left: 2%;
    }

    .ep-image{
        width: 110px;
        height: 110px;
        margin: 5px auto 0 auto;
    }

    .explore-profiles-div h2{
        margin-bottom: 30px;
        text-align: center;
    }

    .ep-web-verified{
        right: 33%;
        bottom: -5px;
    }

    .epc-name{
        text-align: center;
    }

    .ep-stats-div{
        justify-content: center;
    }

    .ep-stats-div .ep-sep-line{
        height: 35px;
        margin: 0px 13px;
    }

    .ep-profile-container{
        padding-top: 4%;
    }

    .ep-view-prof-btn{
        padding: 2%;
    }

    .ep-stats-div .stat .value{
        font-size: 17px;
    }

    .epc-name{
        margin-top: 15px;
        font-size: 20px;
    }

    .ep-view-prof:hover{
        background-color: rgb(63, 163, 240);
        color: #fff;
    }
    
    .ep-request-itin:hover{
        background-color: #d93471;
        color: #fff;
    }

    .explore-profiles-div{
        margin-bottom: 40px;

        .ep-header{
            text-align: left;
            padding: 0 4%;
            font-size: 30px;
        }
    }

    .explore-profiles-div .ep-header{
        margin-bottom: 30px;
    }

    .epp-container-0{
        margin-left: 4%;
    }

    .ep-profile-container .img-container,
    .ep-profile-container .img-container .mantine-Skeleton-root,
    .ep-profile-container .img-container img{
        width: 110px;
        height: 110px;
    }

    .ep-list{
        padding: 30px 0;
    }
}



