.sec-404{
    height: Calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #657786;
}

.sec-404 h2{
    font-size: 2rem;
    font-weight: 500;
    line-height: 2.2;
    color: #23272a;
}

.sec-404 p{
    width: 30%;
    margin: 0;
    text-align: center;
    font-size: 17px;
}

.sec-404 a{
    transition: all .15s ease-in-out;
    color: rgb(63, 163, 240);
}

.fof .header .page-link{
    color: #23272a;
    font-weight: 500;
}

.fof .header .page-link:hover{
    background: rgba(215, 215, 215, 0.4);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    cursor: pointer;
}

@media only screen and (max-device-width: 600px) {
    .sec-404 h2{
        margin-top: 20px;
        margin-bottom: 15px;
    }
    .sec-404 p{
        width: 80%;
        line-height: 1.4em;
        font-size: 19px;
    }
    .fof .header .page-link{
        background: transparent;
    }
}