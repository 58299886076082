.intro-main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; 
    height: 100vh;
    background-color: rgb(10, 50, 77);
    background-position: center; 
    background-repeat: no-repeat; 
    background-size: cover;
    background: linear-gradient(45deg, #4b6cb7, #182848);
    animation: gradientBG 15s linear infinite alternate;
}

.terms .header{
    background-color: #fff;
    border-bottom: 1px solid #ebebeb
}

.intro-img-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro-img img{
    max-width: 475px;
}

.intro-container{
    position: relative;
    display: flex; 
    align-items: center;
    height: 100%;
    width: 1120px; 
}

.intro-div{
    display: grid;
    grid-template-columns: 1.1fr 1fr;
    width: 100%;
    grid-column-gap: 20px;
}

.id-desc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-area: 1/1/span 1/span 1;

    & .it-trips-cnt{
        margin-bottom: 30px;
        letter-spacing: 0.04em;

        & .desc-num{
            font-size: 26px;
            padding: 5px 12px;
            border-radius: 4px;
            background-color: #fff;
            color: rgba(0, 0, 0, 0.9);
            font-weight: 600;
        }

        & .desc-txt{
            color: #fff;
            margin-left: 8px;
            font-size: 18px;
        }
    }
}

.id-desc h1{
    margin: 0;
    letter-spacing: 0.025em;
    color: #fff;
}

.id-desc .id-header-line-1, .id-desc .id-header-line-2{
    padding-left: 2%;
    font-size: 56px;
    font-weight: 600;

    text-shadow: -2px 0px 15px rgba(0,0,0,0.15);
}

.id-desc .id-header-line-2{
    margin-top: 15px;
}

.id-desc .id-header-line-3{
    margin-top: 35px;
}

.id-desc .id-header-line-3{
    display: inline-block;
    text-transform: uppercase;
    background-color: rgb(255,255,255);
    color: rgb(63, 163, 240);
    text-shadow: -2px 0px 15px rgba(0,0,0,0.15);
    font-weight: 600;
    padding: 0.5% 2%;
    border-radius: 3px;
    font-size: 50px;
}

.id-poster{
    grid-area: 1/2/span 1/span 1;
    margin: 0 2% 0 12%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.id-poster img{
    width: 100%;
    height: auto;
}

.intro-header{
    font-size: clamp(48px, 8vw, 66px);
    line-height: 130%;
    font-weight: 700;
}

.intro-body{
    font-size: 30px;
    line-height: 135%;
    font-weight: 500;
    color: rgb(249, 249, 249);
    margin: 16px 0 25px 0;
    transition: all 0.3s ease;
}

.id-lottie{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
    border-radius: 12px;

    display: none;
}

.into-learn-more-btn{
    justify-content: center;
    align-items: center;
    height: 54px;
    width: 180px;
    padding: 0;
    border-radius: 400px;
    background-color: rgb(63, 163, 240);
    font-weight: 600;
    letter-spacing: 0.045em;
    color: #fff;
    transition: all 0.3s ease;
}

.into-learn-more-btn span{
    line-height: 100%;
}

.into-learn-more-btn:hover{
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 1250px){
    .intro-container{
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        margin-left: 2%; 
        margin-right: 2%;
    }
}

@media (max-width: 849px){
    .intro-container{
        position: relative;
        overflow: hidden;
    }

    .intro-div{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-self: flex-start;
        padding: 10% 10% 0 10%;
    }

    .id-desc{
        width: 90%;
    }

    .intro-body{
        margin-bottom: 35px;
    }

    .intro-header{
        text-align: left;
        margin-top: 50px !important;
    }

    .intro-body{
        text-align: left;
    }

    .into-learn-more-btn{
        margin-left: auto;
        margin-right: auto;
    }

    .id-lottie{
        padding: 30px 0;
        width: 80%;
        margin: auto;
    }

    .intro-img-container{
        position: absolute;
        width: 92%;
        height: 100%;
        bottom: -25%;
        left: 4%;
        z-index: -10;
    }

    .intro-img img{
        max-width: 60%;
        margin-left: 20%;
    }
}

@media (max-width: 799px){
    .intro-div{
        display: flex;
        margin: 0 auto;
        width: 90%;
    }

    .id-desc{
        width: 100%;
    }

    .id-poster{
        display: none;
    }

    .id-desc .id-header-line-1, .id-desc .id-header-line-2{
        font-size: 60px;
    }

    .id-desc .id-header-line-3{
        font-size: 55px;
    }

    .id-lottie{
        width: 90%;
    }
}

@media (max-width: 699px){
    .intro-img img{
        max-width: 70%;
        margin-left: 15%;
    }
}

@media (max-height: 800px){
    .intro-img img{
        max-width: 430px;
    }
}

@media (max-height: 650px){
    .intro-img img{
        max-width: 380px;
    }
}

@media only screen and (max-device-width: 600px) {
    .intro-main{
        height: 750px;
    }

    .intro-div{
        width: 100%;
        padding: 40% 2% 0 2%;
    }

    .id-desc .id-header-line-2, .id-desc .id-header-line-3{
        margin-top: 10px;;
    }

    .id-desc .id-header-line-1, 
    .id-desc .id-header-line-2{
        font-size: 35px;
        font-weight: 500;
    }

    .id-desc .id-header-line-3{
        font-size: 35px;
        margin-top: 20px;
    }

    .our-trips{
        display: none !important;
    }

    .intro-header{
        font-size: 32px;
        text-align: left;
        letter-spacing: 0.03em !important;
        margin-top: 0px !important;
    }

    .intro-header span{
        text-transform: lowercase;
    }

    .intro-body{
        font-size: clamp(20px,2vw,22px);
        line-height: 130%;
        text-align: left;
        margin: 14px 0 24px 0;
    }

    .into-learn-more-btn{
        margin-left: inherit;
        height: 42px;
    }

    .id-lottie {
        width: 100%;
        height: 250px;
        background-color: transparent;
    }

    .id-desc{
        & .it-trips-cnt{
            margin-bottom: 25px;
    
            & .desc-num{
                font-size: 22px;
                padding: 4px 10px;
            }
    
            & .desc-txt{
                margin-left: 8px;
                font-size: 16px;
            }
        }
    }

    .intro-img-container{
        bottom: -30%;
    }

    .intro-img img {
        max-width: 96%;
        margin-left: 0%;
    }
}

@keyframes gradientBG {
    0% {
        background-position: 0% 50%;
      }
    100% {
        background-position: 100% 50%;
    }
}

@keyframes arrow-bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-7px);
    }
    60% {
      transform: translateY(-5px);
    }
}