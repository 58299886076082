.travel-passport-container{
    position: relative;
}

.travel-passport-container .ep-cover{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: #F7F7F7;
    z-index: -1;
}

.weekly-newsletter-div{
    width: 1120px;
    margin: 0 auto 0 auto;
    padding-bottom: 100px;
}

.weekly-newsletter-div h2{
    text-align: left;
    font-size: 36px;
    color: #23272a;
}

.weekly-newsletter-div .banner-frame{
    bottom: 0;
}


.weekly-newsletter-div .bc-body-txt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 2%;
}

.travel-passport-div .bc-body-txt h3{
    margin-bottom: 20px;
    font-size: clamp(30px, 3.5vw, 40px);
    line-height: 130%;
    font-weight: 600;
    color: #23272a;
}

.weekly-newsletter-div .bc-body-txt p{
    font-size: clamp(18px,1.5vw,20px);
    color: rgba(0, 0, 0, 0.66);
    line-height: 150%;
    letter-spacing: 0.025em;
    font-weight: 500;
}

.wn-subscribe{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border-radius: 20px;
    height: 36px;
    font-weight: 600;
    letter-spacing: 0.045em;
    color: #fff;
    transition: all 0.3s ease;

    & svg{
        margin-right: 2px;
    }
}

.wn-jw svg{
    animation: arrow-bounce-x 2s infinite;
}

.wn-cl svg{
    animation: arrow-bounce-scale 2s infinite;
}

.wl-success-div{
    & .wn-lc svg{
        margin-right: 2px;
        animation: arrow-bounce-scale 2s infinite;
    }
}

.wn-subscribe span{
    margin-right: 2px;
}

.wn-subscribe:hover{
    cursor: pointer;
}

.wn-subscribe svg{
    margin-left: 5px;
}

.weekly-newsletter-div .banner-img img{
    height: 170px;
    padding: 0;
}

.bc-body-txt .wtb-txt span{
    font-weight: 500;
}

.wtb-div{
    display: flex;
    flex-direction: row;
}

.wtb-div .wtb-txt{
    display: flex;
    align-self: flex-end;
    margin-left: 6px;
    font-size: 14px;
    color: #737373;
    font-weight: 500 !important;
}

.customer-testimonial-modal h2{
    text-align: center;
    font-size: 24px;
    margin-bottom: 15px;
    margin-top: 20px;
    letter-spacing: 0.02em;
    font-weight: 600;
}

.customer-testimonial-modal .ctm-hdr-body{
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 135%;
    text-align: center;
    letter-spacing: 0.025em;
    font-weight: 500;
}

.customer-testimonial-modal .ctm-hdr-body span{
    font-weight: 600;
}

.hth-input{
    position: relative;
}

.hth-input input{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    width: 70%;
    margin-bottom: 20px;
    border: 1px solid #ced4da;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    transition: all 0.25s ease-in-out;
}

.hth-input input:focus{
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 1px rgb(63 163 240 / 30%), 0 1px 1px 0 rgb(0 0 0 / 7%), 0 0 0 3px rgb(63 163 240 / 30%) !important;
}

.hth-input input::placeholder{
    color: #000;
}

.hth-input.error .helper-txt,
.subscribe-interests-container .helper-txt{
    position: absolute;
    display: flex;
    left: 3px;
    bottom: 2px;
    color: #e70000;
    font-size: 12px;
    z-index: 1;
}

.hth-input.error input{
    border-color: #e70000;
}

.subscribe-interests-container .helper-txt{
    bottom: -18px;
}

.customer-testimonial-modal .wn-subscribe{
    margin-left: auto;
    margin-right: auto;
}

.customer-testimonial-modal h4{
    font-size: 20px;
    margin-bottom: 15px;
    margin-top: 5px;
}

.customer-testimonial-modal .wn-subscribe{
    margin-top: 30px;

    .wns-di svg{
        margin: 0;
    }
}

.subscribe-interests-container{
    position: relative;
}

.hdr-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 60px;
}

.hdr-container p{
    margin-left: 10px;
}

.travel-passport-modal .ctm-hdr-body{
    margin: 20px auto 30px auto;
    width: 80%;
    line-height: 150%;
    font-size: 22px;
}

.travel-passport-modal .wn-subscribe{
    margin: 30px auto 20px auto;
}

.travel-passport-modal .wn-subscribe.wn-lc{
    margin-bottom: 25px;
}

.travel-passport-modal .hth-input input{
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    line-height: 34px;
    padding: 3px 13px 3px 13px;
}

.travel-passport-modal .hth-input input::placeholder{
    color: #6b7c93;
}

.travel-passport-modal .hth-input input{
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    outline: none !important;
}

.swm-checkbox-div{
    display: flex;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.swm-checkbox-div .mantine-1137jyz:checked{
    background-color: rgb(63, 163, 240);
    border-color: rgb(63, 163, 240);
}

.swm-checkbox-div .mantine-1jc5t4g{
    padding-left: 10px;
    color: #737373;
}

.travel-passport-div .wn-subscribe span{
    margin-right: 5px;
}

.tp-coming-soon{
    font-size: 13px;
    margin: 0 0 0 8px;
    padding: 2px 7px;
    border-radius: 3px;
    text-transform: uppercase;
    background-color: #f0f2f5;
    color: #000;
    border: 2px solid #f0f2f5;
    font-weight: 600;
}

.si-img-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 180px;
}

.si-img-div .si-img-container{
    justify-content: center;
    align-items: center;
    height: 140px;
    width: 140px;
    border-radius: 140px; 
    background-color: rgb(239, 239, 239);
    overflow: hidden;
}

.si-img-div .si-img-container img{
    height: 140px;
    width: 140px;
    border-radius: 140px;
    object-fit: cover;
}

.si-img-div .si-img-container .mantine-Skeleton-root{
    height: 140px;
    width: 140px;
}

.si-img-div img{
    width: 130px;
    height: 130px;
}

.si-img-div:hover{
    cursor: pointer;
}

.si-img-div .si-name-hdr{
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.03em;
    margin: 10px 0 0px 0;
}

.si-img-div .si-location-hdr{
    font-size: 14px;
    color: #737373;
}

.si-img-div button,
.tp-success-div a{
    color: rgb(63, 163, 240);
}

.tp-success-div p{
    text-align: center;
    color: #484848;
    font-size: 18px;
    letter-spacing: .025em;
    line-height: 1.625em;
    width: 85%;
}

.travel-passport-div .banner-container{
    padding: 5% 4%;
    border: none;
}

.tp-success-div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.hth-input.error .helper-txt{
    bottom: -17px;
    left: calc((100% - 280px)/2 + 3px);
}

.tpc-background{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background-color: #f7f7f7;
}

.wtb-txt-2{
    background-color: #fff;
    color: rgb(63, 163, 240);
    border-radius: 4px;
    padding: 1px 4px;
    margin: 0 5px;
}

.wn-s-cnt-mdl{
    color: #737373;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin: 8px 0 20px 0;
}

.ecp-visible{
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.ecp-invisible{
    opacity: 0;
}


.sm-btns-div .smb-header{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 12px;
}

.sm-btns-div .smb-header .smbdh-line{
    position: absolute;
    top: 10px;
    left: 15%;
    height: 2px;
    width: 70%;
    background-color: #f7f7f7;
    z-index: 0;
}

.sm-btns-div .smb-header p{
    width: auto;
    margin: 0;
    padding: 0 8px;
    background-color: #fff;
    font-size: 13px;
    z-index: 10;
}

.sm-btns-div .container{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.wn-subscribe:disabled {
    cursor: not-allowed;
}

@media (max-width: 1550px){
    .weekly-newsletter-div{
        width: 1100px;
    }
}

@media (max-width: 1155px){
    .weekly-newsletter-div{
        width: 100%;
        padding-left: 2%;
        padding-right: 2%;
        box-sizing: border-box;
    }
}

@media (max-width: 949px){
    .wtb-div{
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .wtb-div .wtb-txt{
        align-self: inherit;
        margin-top: 5px;
    }
}

@media (max-width: 799px){
    .weekly-newsletter-div .bc-body-txt h3{
        width: 100%;
        text-align: center;
    }

    .wn-subscribe{
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-device-width: 601px) {
    .si-view-prof-btn-mob{
        display: none;
    }
}

@media only screen and (max-device-width: 600px) {
    .weekly-newsletter-div{
        padding-bottom: 100px;
    }

    .weekly-newsletter-div h2{
        text-align: center;
        font-size: 30px;
    }

    .wn-subscribe{
        margin-left: inherit;
        margin-right: inherit;
    }

    .wn-subscribe:hover{
        background-color: rgba(63, 163, 240, 1);
    }

    .hth-input input{
        font-size: 16px;
    }

    .tp-coming-soon{
        position: absolute;
        top: -18px;
        right: 10px;
        font-size: 10px;
        padding: 3px 7px 2px 7px;
    }

    .travel-passport-div .banner-img{
        flex-direction: column;
        margin: 20px 0 40px 0;
        background-color: rgb(255, 255, 255);
        border-radius: 12px;
        padding: 7% 0 7% 0;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
        overflow: hidden;
    }

    .travel-passport-div .stat,
    .travel-passport-div .value,
    .travel-passport-div .label{
        width: 80px;
        text-align: center;
    }

    .si-view-prof-btn-web{
        display: none;
    }

    .si-view-prof-btn-mob{
        padding: 14px 0;
        background-color: #fff;
        color: rgb(63, 163, 240);
        width: 100%;
    }

    .travel-passport-div .banner-container{
        padding: 0;
        border: none;
        background-color: transparent;
    }

    .travel-passport-div .hdr-container{
        position: relative;
        margin-bottom: 40px;
        justify-content: left;
        padding-left: 2%;
    }

    .weekly-newsletter-div .bc-body-txt h3{
        text-align: center;
    }

    .weekly-newsletter-div .bc-body-txt p{
        text-align: center;
    }

    .travel-passport-modal .ctm-hdr-body{
        width: 96%;
    }

    .hth-input.error .helper-txt{
        left: calc((100% - 240px)/2 + 3px);
    }

    .travel-passport-container .ep-cover{
        height: 65%;
    }
}

@keyframes arrow-bounce-x {
    0%, 20%, 50%, 80%, 100% {
      transform: translateX(0);
    }
    40% {
      transform: translateX(3px);
    }
    60% {
      transform: translateX(6px);
    }
}

@keyframes arrow-bounce-scale {
    0%, 20%, 50%, 80%, 100% {
        transform: scale(1);
    }
    40% {
        transform: scale(1.3);
    }
    60% {
        transform: scale(1.1);
    }
}