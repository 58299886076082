.our-story-main{
    padding-top: 70px;
}

.our-story-main .header .page-link{
    color: #23272a;
    font-weight: 500;
}

.our-story-main .header {
    position: fixed;
    top: 0;
    left: 0;
    border: none;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    transition: all 0.3s ease;
}

.our-story-container{
    flex-direction: column;
    margin: 10px auto;
    width: 680px;
    letter-spacing: .025em;
}

.our-story-container .os-header{
    margin-top: 10px;
    font-weight: 600;
    font-size: clamp(28px,2.5vw,42px);
}

.our-story-container .more-reads-header{
    margin: 0;
    font-weight: 600;
    font-size: clamp(20px,2.5vw,32px);
}

.our-story-container .os-header-footer,
.our-story-container .mr-article-header-footer{
    margin-top: 5px;
    color: #737373;
    font-size: 14px;
}

.our-story-container .os-question,
.our-story-container .mr-article-header{
    margin-top: 40px;
    font-weight: 600;
    color: rgba(0,0,0,0.9);
    font-size: clamp(18px,2vw,20px);
}

.our-story-container .mr-article-section{
    margin-top: 15px;
    margin-bottom: 15px;
}

.our-story-container .os-response{
    margin-top: 40px;
    color: rgba(0, 0, 0, .9);
    font-size: clamp(18px,2vw,20px);
    line-height: 1.625em;
}

.our-story-container .os-rfs{
    margin-top: 20px;
    margin-bottom: 40px;
    text-decoration: underline !important;
    color: rgba(0, 0, 0, .9);
    font-size: clamp(16px,2vw,18px);
    line-height: 1.525em;
}

.our-story-container .os-image-1{
    margin-top: 40px;
}

.our-story-container .os-image-1 img{
    border-radius: 4px;
}

.our-story-container .os-image-2,
.our-story-container .os-image-3,
.our-story-container .os-image-4{
    margin-top: 40px;
}

.our-story-container .os-img-label{
    margin-top: 7px;
    font-size: 14px;
    color: #737373;
    text-align: center;
}

.os-image-1{
    width: 680px;
    height: 382.5px
}

.os-image-3{
    width: 680px;
    height: 451.5px;
}

.os-image-4{
    width: 680px;
    height: 397px;
}

@media (max-width: 749px){
    .our-story-container{
        width: 100%;
        padding: 0 4%;
    }

    .our-story-container .os-question{
        margin-top: 30px;
    }

    .our-story-container .os-response{
        margin-top: 30px;
    }

    .our-story-container .os-rfs{
        margin-bottom: 30px;
    }

    .our-story-container .os-image-1{
        margin-top: 30px;
    }

    .our-story-container .os-image-2,
    .our-story-container .os-image-3{
        margin-top: 30px;
    }

    .os-image-1{
        height: auto;
        width: 92vw;
        aspect-ratio: 680 / 382.5;
    }

    .os-image-3{
        height: auto;
        width: 92vw;
        aspect-ratio: 680 / 451.5;
    }

    .os-image-4{
        height: auto;
        width: 92vw;
        aspect-ratio: 680 / 397;
    }
}

@media only screen and (max-device-width: 600px) {

}