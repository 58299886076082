.main-page{
    & .header{
        .container-jwl{
            position: relative;
            display: flex;

            .btn-hdr-wl{
                display: flex;
                align-self: center;
                justify-content: center;
                align-items: center;
                border: none;
                background-color: #fbfbfb;
                height: 36px;
                padding: 3px 26px;
                border-radius: 4px;
            }

            & p{
                position: absolute;
                bottom: -7px;
                color: #fff;
                font-size: 12px;
                width: 100%;
                text-align: center;
            }
        }
    }
}

.grey-section{
    background-color: rgb(249, 249, 249);
}

.loading-view{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,.3);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.area-list-dropdown .mantine-y414p,
.languages-list-dropdown .mantine-y414p{
    padding: 8px 12px;
    height: 20px;
    box-sizing: content-box;
    width: 146px;
}

.area-list-dropdown button{
    color: #23272a;
}

.languages-list-dropdown{
    height: 190px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.bottom-container{
    position: relative;
    margin-top: 50px;
    padding-bottom: 40px;
}

@keyframes opacityOn {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-device-width: 600px) {
    .grey-section{
        background-color: transparent;
    }

    .bottom-container{
        padding-bottom: 20px;
    }
}