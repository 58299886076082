.delete-instagram-main{
    padding-top: 70px;
}

.delete-instagram-main .header .page-link{
    color: #23272a;
    font-weight: 500;
}

.delete-instagram-main .header {
    position: fixed;
    top: 0;
    left: 0;
    border: none;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    transition: all 0.3s ease;
}

.delete-instagram-container{
    flex-direction: column;
    margin: 10px auto;
    width: 680px;
    letter-spacing: .025em;
}

.delete-instagram-container .di-header{
    margin-top: 10px;
    font-weight: 600;
    font-size: clamp(28px,2.5vw,42px);
}

.delete-instagram-container .di-header-footer{
    margin-top: 5px;
    color: rgb(107, 107, 107);
    font-size: 14px;
}

.delete-instagram-container .di-text-description{
    margin-top: 20px;
    color: rgba(0, 0, 0, .9);
    font-size: clamp(16px,2vw,20px);
    line-height: 160%;
}

.di-image-1{
    margin-top: 30px;
    width: 680px;
    height: 451.5px;
}

.di-eab-img-container,
.di-explore-arvel-btn img{
    width: 55px !important;
    height: 55px !important;
}

.di-explore-arvel-btn{
    width: 250px;
    height: 66px;
    padding-right: 10px;
    background-color: rgb(63, 163, 240);
    border-radius: 40px;
    margin: 40px auto;
    justify-content: center;
    align-items: center;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -4px rgba(0,0,0,.1);
}

.di-explore-arvel-btn p{
    margin: 0;
    color: #fff;
    font-size: 18px;
    line-height: 130%;
    width: 150px;
    font-weight: 600;
}

.di-section-header{
    color: #000;
    font-size: clamp(22px, 2vw, 26px);
    font-weight: 600;
    margin-top: 20px;
}

.delete-instagram-container a{
    color: rgb(63, 163, 240);
}

.di-text-description-ul{
    margin-left: 40px;
    margin-top: 15px;
    list-style-type: decimal;
}

.di-text-description-li::marker {
    font-weight: bold; /* Bold numbers */
    margin-right: 100px;
}

.di-text-description-li{
    padding-left: 15px;
    color: #21455c;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
}

.di-td-li-margin-bottom{
    margin-bottom: 10px;
}

.di-li-phone-screen{
    justify-content: center;
    align-items: center;
}

.di-li-phone-screen,
.di-li-phone-screen img{
    width: 300px !important;
    height: calc(300 / 623 * 1280px) !important;
}

.di-li-phone-screen-container{
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 30px;
    margin-bottom: 30px;
    height: calc(300 / 623 * 1280px) !important;
}

.di-li-web-dda-s1-container,
.di-li-web-dda-s2-container,
.di-li-web-dda-s3-container{
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100% !important;
}

.di-li-web-dda-s1,
.di-li-web-dda-s1 img,
.di-li-web-dda-s2,
.di-li-web-dda-s2 img,
.di-li-web-dda-s3,
.di-li-web-dda-s3 img{
    width: 400px !important;
}

.di-li-web-dda-s1,
.di-li-web-dda-s1 img{
    height: calc(400 / 701 * 826px) !important;
}

.di-li-web-dda-s2,
.di-li-web-dda-s2 img{
    height: calc(400 / 1096 * 848px) !important;
    border-radius: 18px;
}

.di-li-web-dda-s3,
.di-li-web-dda-s3 img{
    height: calc(400 / 1095 * 1246px) !important;
    border-radius: 18px;
}

.di-li-web-dda-s2 img,
.di-li-web-dda-s3 img{
    border: 1px solid #dbdbdc;
}

.di-li-web-dda-s1,
.di-li-web-dda-s2,
.di-li-web-dda-s3{
    justify-content: center;
}

.di-content-overview-container{
    flex-direction: row;
    flex-wrap: wrap;
}

.di-content-overview-container button{
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    background-color: rgb(239, 239, 239);
    border: none;
    border-radius: 8px;
    padding: 3px 10px;
    font-size: 14px;
    letter-spacing: 0.02em;
    text-decoration: underline;
    transition: all 0.2s ease-out;
}

.di-content-overview-container button:hover{
    background-color: rgb(225, 225, 225);
}

.delete-instagram-container .di-img-label{
    margin-top: 7px;
    font-size: 14px;
    color: #737373;
    text-align: center;
}

.di-daoi,
.di-daoi,
.di-dvs,
.di-dopcmac{
    margin-left: 10px;
}

@media (max-width: 749px){
    .delete-instagram-container{
        width: 100%;
        padding: 0 4%;
    }

    .di-image-1{
        height: auto;
        width: 92vw;
        aspect-ratio: 680 / 451.5;
    }

    .di-li-phone-screen, 
    .di-li-phone-screen img{
        width: calc((92vw - 30px) / 2) !important;
        height: auto !important;
    }

    .di-li-phone-screen-container{
        height: calc(((92vw - 30px) / 2) * (1280 / 623)) !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-device-width: 600px) {
    .di-text-description-li{
        padding-right: 15px;
    }

    .di-li-phone-screen, .di-li-phone-screen img{
        width: calc((92vw - 80px) / 2) !important;
    }

    .di-content-overview-container button:hover{
        background-color: rgb(239, 239, 239);
    }

    .di-dopcmac{
        margin-left: 0px;
    }

    .di-li-web-dda-s1,
    .di-li-web-dda-s1 img,
    .di-li-web-dda-s2,
    .di-li-web-dda-s2 img,
    .di-li-web-dda-s3,
    .di-li-web-dda-s3 img{
        width: calc(92vw - 80px) !important;
    }

    .di-li-web-dda-s1,
    .di-li-web-dda-s1 img{
        height: calc((92vw - 80px) * 826 / 701) !important;
    }

    .di-li-web-dda-s2,
    .di-li-web-dda-s2 img{
        height: calc((92vw - 80px) / 1096 * 848) !important;
    }

    .di-li-web-dda-s3,
    .di-li-web-dda-s3 img{
        height: calc((92vw - 80px) / 1095 * 1246) !important;
        border-radius: 18px;
    }
}