.main .email{
    display: flex;
    justify-content: center;
    width: 30%;
    margin: 10px auto;
}

.waitlist .header{
    position: fixed;
    border: none;
    background-color: transparent;
}

.waitlist .header .page-link{
    color: #23272a;
    font-weight: 500;
}

.waitlist .at-banner-img{
    justify-content: center;
    align-items: center;
    margin: 40px 0;
}

.waitlist .at-banner-img .at-img-div{
    flex-direction: column;
    align-items: center;
    width: 170px;
}

.waitlist .at-banner-img .at-img-div:hover{
    cursor: pointer;
}

.waitlist .at-banner-img .at-img-div .at-name-hdr{
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.03em;
    margin: 15px 0 2px 0;
}

.waitlist .at-banner-img .at-img-div .at-location-hdr{
    font-size: 14px;
    color: #737373;
}

.waitlist .at-banner-img .at-img-div button{
    color: rgb(63, 163, 240);
}

.at-img-div .at-img-container{
    justify-content: center;
    align-items: center;
    height: 170px;
    width: 170px;
    border-radius: 170px;
    background-color: rgb(239, 239, 239);
    overflow: hidden;
}

.at-img-div .at-img-container img{
    height: 170px;
    width: 170px;
    border-radius: 170px;
    object-fit: cover;
}

.at-img-div .at-img-container .mantine-Skeleton-root{
    height: 170px;
    width: 170px;
}

.waitlist .at-body-txt{
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.waitlist .at-body-txt h3{
    color: #23272a;
    font-size: clamp(22px,3.5vw,28px);
    font-weight: 600;
    line-height: 130%;
    margin-top: 30px;
    margin-bottom: 20px;
}

.waitlist .at-body-txt p{
    text-align: center;
    color: #484848;
    font-size: clamp(18px,1.5vw,20px);
    font-weight: 500;
    letter-spacing: .025em;
    line-height: 150%;
    width: 80%;
}

.waitlist .wn-subscribe{
    margin-top: 30px;
}

.waitlist .wtb-div{
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.waitlist .wtb-div .wtb-txt{
    align-self: inherit;
    margin-top: 5px;
}

.wl-success-div p{
    margin: auto;
    text-align: center;
    color: rgba(0,0,0,.66);
    font-size: clamp(17px,1.5vw,19px);
    font-weight: 500;
    letter-spacing: .025em;
    line-height: 150%;
    width: 90%;
}

.wl-success-div svg{
    margin-right: 3px;
}

.wl-scrolled .header{
    position: fixed !important;
    border-bottom: 1px solid #ebebeb;
    background-color: #fff;
    transition: all 0.3s ease;
}

.waitlist-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: calc(100vh);
    width: 100%;
}

.waitlist-body .cb-clm-left{
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, #4b6cb7, #182848);
}

.waitlist-body .cb-clm-right{
    align-items: center;
    padding: 70px 30px;
}

.waitlist-body .cb-clm-right .cb-clmr-inner{
    width: 560px;
}

.waitlist-body .cb-clm-right .cb-clmr-inner .header-h3{
    position: relative;
    display: inline-block;
    font-size: 38px;
    font-weight: 600;
}

.waitlist-body .cb-clm-right .cb-clmr-inner .header-h3 div{
    position: absolute;
    right: -120px;
    top: -10px;
    background-color: #f0f2f5;
    border-radius: 7px;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    height: 26px;
    width: 125px;
}

.waitlist-body .cb-clm-right .cb-clmr-inner .header-h3 div span{
    margin-bottom: 3px;
}

@media (max-width: 1049px){
    .waitlist-body .cb-clm-right .cb-clmr-inner{
        width: 100%;
    }
}

@media (max-width: 1049px){
    .waitlist-body{
        grid-template-rows: auto auto;
        grid-template-columns: none;
    }

    .waitlist .header{
        position: absolute;
        top: 0;
        left: 0;
    }

    .waitlist .header .page-link{
        color: #fff;
    }

    .waitlist .footer{
        position: inherit;
        background-color: inherit;
    }

    .waitlist-body .cb-clm-left{
        height: 400px;
    }

}

@media (max-height: 799px) and (min-width: 849px) {
    .waitlist  .footer{
        display: none;
    }
}

@media only screen and (min-device-width: 849px) {
    .waitlist-body .header{
        display: none;
    }
}

@media only screen and (max-device-width: 600px) {
    .waitlist .at-view-prof-btn-web{
        display: none;
    }
    
    .waitlist .header .page-link{
        background: transparent;
    }

    .waitlist .at-banner-img{
        flex-direction: column;
    }

    .waitlist .at-banner-img .at-img-div{
        width: auto;
    }

    .waitlist .at-banner-img .at-img-div .at-img-container{
        width: 140px;
        height: 140px;
    }

    .waitlist .at-banner-img .at-name-hdr{
        font-size: 20px;
        margin: 10px 0 2px;
    }

    .waitlist .si-view-prof-btn-mob{
        background-color: transparent;
        padding-top: 18px;
    }

    .waitlist .at-body-txt p{
        width: 96%;
    }

    .waitlist-body .cb-clm-right{
        padding: 40px 3% 60px 3%;
    }

    .wl-scrolled  .header {
        border-bottom: none;
        background-color: transparent;
    }

    .waitlist-body .cb-clm-right .cb-clmr-inner .header-h3{
        position: initial;
        font-size: 30px;
    }

    .waitlist-body .cb-clm-right .cb-clmr-inner .header-h3 div{
        right: 0px;
        top: -20px;
    }

    .wl-success-div p{
        width: 94%;
    }

    .at-img-div .at-img-container,
    .at-img-div .at-img-container img,
    .at-img-div .at-img-container .mantine-Skeleton-root{
        height: 140px;
        width: 140px;
    }
}