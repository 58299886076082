.how-it-works-frame{
    margin: 120px auto 150px auto;
}

.how-it-works-frame h2{
    width: 1120px;
    margin: 0 auto 60px auto;
    text-align: left;
    font-size: 36px;
    color: #23272a;
}

.hiw-main-container{
    display: grid;
    grid-template-rows: auto auto;
    width: 100%;
    grid-row-gap: 125px;
}

.hiw-node-1-container,
.hiw-node-2-container,
.hiw-node-3-container,
.hiw-node-4-container{
    display: grid;
    width: 100%;
    grid-column-gap: 40px;
}

.hiw-node-1-container,
.hiw-node-3-container{
    grid-template-columns: 1fr 1.2fr;
}

.hiw-node-2-container,
.hiw-node-4-container{
    grid-template-columns: 1.2fr 1fr;
}

.hiw-node-media-container-1 .img-container,
.hiw-node-media-container-2 .img-container{
    width: 400px;
    height: 400px;
}

.hiw-node-media-container-1 .img-container .mantine-5xwvpy,
.hiw-node-media-container-2 .img-container .mantine-5xwvpy{
    width: 400px;
    height: 400px;
}

.hiw-node-media-container-1 img,
.hiw-node-media-container-2 img,
.hiw-node-media-container-3 img,
.hiw-node-media-container-4 img{
    height: 100%;
    width: auto;
}

.hiw-desc-header{
    display: flex;
    flex-direction: column;
}

.hiw-desc-header h3{
    margin: 0;
    font-size: clamp(30px, 4vw, 42px);
    line-height: 130%;
    font-weight: 600;
    color: #23272a;
}

.hiw-desc-body{
    margin-top: 15px;
    font-size: clamp(18px,2vw,20px);
    color: rgba(0, 0, 0, 0.66);
    line-height: 1.625em;
    letter-spacing: 0.025em;
}

.hiw-desc-container-1,
.hiw-desc-container-2,
.hiw-desc-container-3,
.hiw-desc-container-4{
    display: flex;
    align-items: center;
    align-self: center;
    padding: 4% 10%;
    background-color: #fff;
    border: 1px solid rgb(239, 243, 244);
}

.hiw-desc-container-1,
.hiw-desc-container-3{
    border-radius: 12px 0 0 12px;
    border-right: none;
}

.hiw-desc-container-2,
.hiw-desc-container-4{
    border-radius: 0 12px 12px 0;
    border-left: none;
}

.hiw-node-media-container-1,
.hiw-node-media-container-3{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 17%;
}

.hiw-node-media-container-2,
.hiw-node-media-container-4{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 17%;
}

.hiw-desc-container-inner{
    width: 60%;
    min-height: 60%;
    margin-right: 10%;
}

.hiw-desc-container-2 .hiw-desc-container-inner,
.hiw-desc-container-4 .hiw-desc-container-inner{
    margin-left: auto;
}

.hiw-actn-btn{
    padding: 10px 20px 10px 0px;
    color: rgb(63, 163, 240);
    font-weight: 600;
    letter-spacing: 0.025em;
}

@media (max-width: 1500px){
    .hiw-desc-container-inner{
        width: 58%;
    }
}

@media (max-width: 1400px){
    .hiw-desc-container-inner{
        width: 85%;
    }
}

@media (max-width: 1200px){
    .hiw-desc-container-inner{
        width: 90%;
    }

    .hiw-node-media-container-1, 
    .hiw-node-media-container-3{
        padding-right: 7%;
    }
}

@media (max-width: 1155px){
    .hiw-desc-container-inner{
        width: 90%;
    }

    .how-it-works-frame h2{
        width: 100%;
        padding: 0 2%;
        box-sizing: border-box;
    }

    .hiw-node-media-container-1 .img-container,
    .hiw-node-media-container-2 .img-container{
        width: 350px;
        height: 350px;
    }

    .hiw-node-media-container-1 .img-container .mantine-5xwvpy,
    .hiw-node-media-container-2 .img-container .mantine-5xwvpy{
        width: 350px;
        height: 350px;
    }

    .hiw-node-media-container-1 img,
    .hiw-node-media-container-2 img,
    .hiw-node-media-container-3 img,
    .hiw-node-media-container-4 img{
        height: 100%;
        width: auto;
    }
}

@media (max-width: 900px){
    .hiw-desc-container-inner{
        width: 80%;
    }

    .hiw-main-container{
        grid-row-gap: 90px;
    }

    .hiw-node-1-container,
    .hiw-node-2-container{
        grid-row-gap: 35px;
        grid-template-columns: none;
        grid-column-gap: 0;
        grid-template-rows: auto;
    }

    .hiw-node-2-container,
    .hiw-node-4-container{
        grid-template-columns: none;
        grid-column-gap: 0;
        display: flex;
        flex-direction: column-reverse;
    }

    .hiw-desc-container-1, 
    .hiw-desc-container-3{
        width: 95%;
        margin-left: 5%;
    }

    .hiw-desc-container-2,
    .hiw-desc-container-4{
        width: 95%;
        margin-right: 5%;
    }

    .hiw-node-media-container-1,
    .hiw-node-media-container-2,
    .hiw-node-media-container-3,
    .hiw-node-media-container-4{
        padding: 0;
        justify-content: center;
    }

    .hiw-node-media-container-1 .img-container,
    .hiw-node-media-container-2 .img-container{
        width: 400px;
        height: 400px;
    }

    .hiw-node-media-container-1 .img-container .mantine-5xwvpy,
    .hiw-node-media-container-2 .img-container .mantine-5xwvpy{
        width: 400px;
        height: 400px;
    }

    .hiw-node-media-container-1 img,
    .hiw-node-media-container-2 img,
    .hiw-node-media-container-3 img,
    .hiw-node-media-container-4 img{
        height: 100%;
        width: auto;
    }

    .hiw-desc-container-1, 
    .hiw-desc-container-2, 
    .hiw-desc-container-3, 
    .hiw-desc-container-4{
        height: auto;
    }
}

@media (max-width: 849px){
    .how-it-works-frame{
        margin-top: -40px;
        margin-bottom: 100px;
    }
}

@media only screen and (max-device-width: 600px) {
    .how-it-works-frame{
        margin-bottom: 70px;
        margin-top: -50px;
    }

    .how-it-works-frame h2{
        text-align: left;
        font-size: 30px;
        margin-bottom: 30px;
        padding: 0 4%;
        line-height: 1.7em;
    }

    .hiw-node-media-container-1 img, 
    .hiw-node-media-container-2 img, 
    .hiw-node-media-container-3 img,
    .hiw-node-media-container-4 img{
        width: 85vw;
        height: 85vw;
    }

    .hiw-node-media-container-1 .img-container,
    .hiw-node-media-container-2 .img-container{
        width: 85vw;
        height: 85vw;
        margin: 15px 0;
    }

    .hiw-node-media-container-1 .img-container .mantine-5xwvpy,
    .hiw-node-media-container-2 .img-container .mantine-5xwvpy{
        width: 85vw;
        height: 85vw;
    }

    .hiw-node-1-container, 
    .hiw-node-3-container{
        grid-row-gap: 15px;
    }

    .hiw-desc-container-1, 
    .hiw-desc-container-2, 
    .hiw-desc-container-3, 
    .hiw-desc-container-4{
        padding: 7%;
    }

    .hiw-desc-container-inner{
        margin: 0;
        width: 95%;
    }

    .hiw-desc-body{
        margin: 10px 0;
        text-align: left;
    }

    .hiw-desc-header h3{
        font-size: clamp(20px, 7vw, 42px);
        line-height: 130%;
        text-align: left;
    }

    .hiw-main-container{
        grid-row-gap: 35px;
    }

    .hiw-desc-header{
        justify-content: flex-start;
    }

    .hiw-actn-btn{
        width: 100%;
        padding-right: 0;
        padding-top: 0;
        text-align: left;
    }

    .hiw-node-img-1,
    .hiw-node-img-2,
    .hiw-node-img-3{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}



